.wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.white {
  color: #fff;
}
.blue {
  color: #0066ff;
}
.gray {
  color: #808080;
}
.coinIcon {
  cursor: pointer;
  max-width: 100%;
  height: auto;
  padding-top: 10px;
}
.coin {
  height: 20px;
}
.hr {
  border: 1px solid #808080;
}
.rewardsContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}
.coinLabel {
  font-size: 17px;
  padding-left: 5px;
}
