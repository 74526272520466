.container {
  border-top: 1px solid #808080;
  border-left: 1px solid #808080;
  border-right: 1px solid #808080;
  border-radius: 15px 15px 0px 0px;
  height: 100px;
  width: 200px;
  display: grid;
}
.container div {
  margin: auto;
}
.subName {
  margin-left: 8px;
}
.footerContainer {
  border: 1px solid #808080;
  border-radius: 0px 0px 15px 15px;
  height: 100px;
  width: 200px;
  display: grid;
}
.footerContainer div {
  margin: auto;
}
.purchase {
  margin-top: 20px !important;
}
.userName {
  margin-bottom: 20px !important;
}
.buttonStyle {
  background-color: yellow;
  color: black;
  border: none;
  height: 40px;
  width: 90px;
  border-radius: 10px 10px 10px 10px;
}
.dataHandler {
  cursor: pointer;
}
.namePadding {
  margin-left: 40%;
}
