.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 16px;
  margin-bottom: 20px;
  min-width: 328px !important;
  // padding: 0 36px;
  .text {
    width: 100%;
  }
  .readMore {
    font-size: 14px;
    text-align: left;
    width: 100%;
    max-width: 1024px;
    color: #808080;
    text-decoration: underline;
    cursor: pointer;
    margin-bottom: 20px;
  }

  .media {
    // max-width: 1024px;
    // text-align: center;
    // width: 10%;
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
    width: 200px;
    .img {
      // display: none;
      width: 100%;
    }
  }
}

@media only screen and (min-width: 767px) {
  .wrapper {
    width: inherit;
    // max-width: 1024px;
    min-width: 1024px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 16px;
    margin-bottom: 20px;
    // padding: 0 36px;
    .text {
      width: 100%;
      margin: 20px 0 5px 0;
      font-size: 14px;
      max-width: 1024px;
      display: block;
      text-overflow: ellipsis;
      word-wrap: break-word;
      overflow: hidden;
      max-height: 4.5em;
      line-height: 1.5em;
    }
    .readMore {
      font-size: 14px;
      text-align: left;
      width: 100%;
      max-width: 1024px;
      color: #808080;
      text-decoration: underline;
      cursor: pointer;
      margin-bottom: 20px;
    }

    .media {
      // max-width: 1024px;
      // text-align: center;
      // width: 10%;
      display: flex;
      justify-content: center;
      margin-bottom: 10px;
      width: 500px;
      .img {
        // display: none;
        width: 100%;
      }
    }
  }
}
