.wrapper {
  width: 100%;
  max-width: 1024px;
  margin-top: 30px;
  padding-left: 20px;

  div {
    margin-top: 7.6%;
  }
  .description {
    width: 100%;
    font-size: 14px;
    line-height: 20px;
    text-align: left;
    color: #fff;
  }
  .readMore {
    display: flex;
    align-items: center;
    column-gap: 10px;
    margin-top: -7%;
    .readMoreText {
      font-size: 14px;
      line-height: 20px;
      text-align: left;
      color: #808080;
      cursor: pointer;
    }

    .arrow {
      cursor: pointer;
      img {
        max-width: 100%;
        height: auto;
      }
    }
  }
  .edit {
    max-width: 1024px;
    display: flex;
    align-items: center;
    justify-content: center;
    button {
      width: 100%;
      margin-top: 20px;
      max-width: 300px;
      height: 40px;
      border-radius: 20px;
      border: 1px solid #808080;
      outline: none;
      background-color: transparent;
      font-weight: bold;
      font-size: 14px;
      letter-spacing: 0.03em;
      line-height: 20px;
      text-align: center;
      color: #808080;
      text-transform: uppercase;
    }
  }
}
.modal {
  background-color: #000000;
  border-color: #03adfc;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: "scroll";
  width: 100%;
}
.divTextField {
  margin: 25px;
  padding: 20px;
}
.submitbutton {
  width: 100%;
  height: 40px;
  background: linear-gradient(
    90deg,
    rgba(0, 72, 255, 1) 0%,
    rgba(0, 238, 255, 1) 100%
  );
  margin-top: 50px;
}
.parent {
  margin-left: 30%;
}
.title {
  color: white;
}
.body {
  color: #868686;
  margin-top: 12px;
}
.iconStyle {
  color: #808080;
}
.header {
  color: #868686;
}
