.textStyle {
  margin-top: 6px !important;
  margin-left: 10px !important;
  font-size: 20px !important;
}
.parentStyle {
  display: flex;
  margin-top: 5px !important;
}
.buttonStyle {
  margin-bottom: 18px !important;
  margin-left: 20% !important;
  color: cornflowerblue !important;
  outline: none !important;
  background: none !important;
}
.alignAccount {
  display: flex;
  width: 50%;
}
.dialogHeight {
  min-height: 400px !important;
}
