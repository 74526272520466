.keepbuttons {
  width: 80%;
  margin-left: 10%;
  border-radius: 15px 15px 15px 15px;
  height: 40px;
  border: 3px solid #2196f3;
  margin-top: 5px;
}

.dummyText {
  text-emphasis-color: black !important;
}
.detail {
  color: #808080;
}
.deletebuttons {
  width: 80%;
  margin-left: 10%;
  background: white;
  border: 3px solid #2196f3;
  margin-top: 12px !important;
  margin-bottom: 12px !important;
  border-radius: 15px 15px 15px 15px;
  height: 40px;
}
.disablebutton {
  background: transparent;
  border: none;
  color: #2196f3;
}
