.wrapper {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 20px 0 20px;

  .logo {
    margin-top: 40px;
  }

  .content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 8px;
    margin-bottom: 20px;
    max-width: 550px;
    .heading {
      font-weight: 700;
      width: 100%;
    }
    .text {
      width: 100%;
      line-height: 1.5rem;
      font-size: 14px;
      color: #808080;
    }
  }

  .card {
    margin-top: 40px;
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    .main {
      max-width: 550px;
      width: 100%;
      form {
        width: 100%;
        input {
          width: 100%;
          background-color: transparent;
          outline: none;
          border-top: 1px solid transparent;
          border-right: 1px solid transparent;
          border-left: 1px solid transparent;
          border-bottom: 1px solid #808080;
          height: 50px;
          color: white;
          margin: 10px 0;
          padding: 0 5px;
          font-weight: 300;
          font-size: 14px;
        }
        ::-webkit-input-placeholder {
          /* Edge */
          color: #808080;
          font-family: Roboto;
          font-weight: 300;
          font-size: 14px;
        }
        :-ms-input-placeholder {
          /* Internet Explorer 10-11 */
          color: #808080;
          font-family: Roboto;
          font-weight: 300;
          font-size: 14px;
        }
        ::placeholder {
          color: #808080;
          font-family: Roboto;
        }
        input:focus {
          border-bottom: 1px solid #0048ff;
          font-weight: 300;
          font-size: 14px;
        }
        .button {
          width: 100%;
          height: 40px;
          background: linear-gradient(
            90deg,
            rgba(0, 72, 255, 1) 0%,
            rgba(0, 238, 255, 1) 100%
          );
          border-radius: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0 2px;
          margin-top: 30px;
          button {
            width: 100%;
            text-transform: uppercase;
            height: 36px;
            border: 1px solid transparent;
            border-radius: 18px;
            background-color: black;
            color: #00eeff;
            font-weight: 700;
            outline: none;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1024px) {
  .wrapper {
    background-image: url("../../assets/images/web.jpg");
    background-color: #cccccc;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;

    .card {
      background-color: black;
      border-radius: 10px;
      padding: 40px 40px 0 40px;
      max-width: 550px;
      max-height: 600px;
      box-shadow: 0px 34px 33px rgba(22, 28, 45, 0.13);
      margin-top: 20px;

      .main {
      }
    }
  }
}

@media only screen and (min-width: 1200px) {
  .wrapper {
    background-image: url("../../assets/images/new_web.jpg");
    .logo {
      position: absolute;
      margin-top: 0;
      top: 40px;
      left: 40px;
    }
    .card {
      position: absolute;
      right: 60px;
    }
  }
}

@media only screen and (min-width: 1440px) {
  .wrapper {
    .logo {
      position: absolute;
      margin-top: 0;
      top: 40px;
      left: 97px;
    }
    .card {
      position: absolute;
      right: 80px;
    }
  }
}
