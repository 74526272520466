.wrapper {
  width: 100%;
  max-width: 1024px;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  column-gap: 10px;
  .userInfo {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    align-items: flex-start;
    justify-content: space-between;

    .info {
      display: flex;
      flex-direction: column;
      row-gap: 10px;
      .infoTop {
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
        column-gap: 7px;
        .name {
          color: #808080;
          font-weight: 700;
          font-size: 14px;
        }
        img[alt="verified"] {
          max-width: 100%;
          height: auto;
        }
        .followers {
          font-weight: 700;
          color: #0048ff;
          font-size: 14px;
        }
        .time {
          color: #808080;
          font-size: 12px;
          font-weight: 700;
        }
      }
      .nickname {
        color: #737373;
        font-weight: 500;
        font-size: 14px;
      }
    }

    .options {
      height: 20px;
      width: 79px;
      cursor: pointer;
      display: flex;
      // justify-content: flex-end;
      img[alt="options"] {
        max-width: 100%;
        height: auto;
      }
      .icon {
        stroke: blue;
        stroke-width: 2px;
      }
      .icon path {
        stroke: blue;
        stroke-width: 2px;
      }
    }
  }
}
