.toolbar {
  position: fixed !important;
  top: 103px !important;
  left: 0;
  /* right: 1570px; */
  display: flex;
  align-items: center;
  font-weight: 500;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(20px);
}
@media only screen and (min-width: 764px) {
  .toolbar {
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(20px);

    width: 300px;
    display: flex;
    align-items: center;
    font-weight: 500;
  }
}

@media only screen and (min-width: 864px) {
  .toolbar {
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(20px);

    left: 0;
    right: 533px;
    display: flex;
    align-items: center;

    font-weight: 500;
  }
}
/*
@media only screen and (min-width: 856px) {
  .toolbar {
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(20px);
    top: 90px !important;
    left: 0;
    right: 526px;
    display: flex;
    align-items: center;
    font-weight: 500;
  }
}

@media only screen and (min-width: 877px) {
  .toolbar {
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(20px);
    top: 90px !important;
    left: 0;
    right: 632px;
    display: flex;
    align-items: center;

    font-weight: 500;
  }
}

@media only screen and (min-width: 1750px) {
  .toolbar {
    left: calc(100% - 80%) !important;
  }
}
*/
/* @media only screen and (min-width: 1850px) {
  .toolbar {
    position: fixed !important;
    top: 103px !important;
    left: 0;
    right: 1570px;
    display: flex;
    align-items: center;

    font-weight: 500;

    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(20px);
  }
}
@media only screen and (max-width: 764px) {
  .content-toolbar {
    left: calc(100% - 60%);
  }
} */

.toolbar-title {
  margin: 0;
  font-size: 20px;
  font-weight: 800;
  color: white;
  text-transform: capitalize;
}

.left-items,
.right-items {
  flex: 1;
  padding: 10px;
  display: flex;
}

.right-items {
  flex-direction: row-reverse;
}

.left-items .toolbar-button {
  margin-right: 20px;
}

.right-items .toolbar-button {
  margin-left: 20px;
}

.left-items .toolbar-button:last-child,
.right-items .toolbar-button:last-child {
  margin: 0;
}
