.wrapper {
  width: 100%;
  // max-width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  .header {
    min-width: 57%;
    min-height: 500px;
    background-position: center;
    background-repeat: no-repeat;
    // background-size: contain;
    object-fit: cover;
    padding: 20px;
    position: relative;
    .buy {
      display: flex;
      align-items: center;
      justify-content: center;
      column-gap: 10px;
      width: 100%;
      .icon {
        cursor: pointer;
        img {
          max-width: 100%;
          height: auto;
        }
      }
      .buyText {
        cursor: pointer;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        text-align: left;
        color: #ffd949;
      }
    }
    .headerContent {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      position: relative;
      bottom: -200px;
      row-gap: 20px;

      .itemInfo {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: nowrap;

        .itemDetails {
          .itemName {
            font-weight: bold;
            font-size: 16px;
            line-height: 24px;
            text-align: left;
            color: #fff;
          }
          .itemType {
            font-weight: normal;
            font-size: 14px;
            line-height: 20px;
            text-align: left;
            color: #808080;
          }
          .itemDate {
            font-weight: normal;
            font-size: 14px;
            line-height: 20px;
            text-align: left;
            color: #808080;
          }
        }
        .rating_success {
          border: 1px solid #0f8;
        }
        .rating_danger {
          border: 1px solid #db462c;
        }
        .itemRating {
          padding: 10px;
          width: 70px;
          height: 63px;
          border-radius: 10px;
          background: transparent;
          display: flex;
          flex-direction: column;
          row-gap: 2px;
          align-items: center;
          justify-content: center;

          .ratingHeading {
            word-break: break-word !important;
            text-transform: uppercase;
            font-weight: normal;
            font-size: 12px;
            line-height: 20px;
            text-align: center;
            color: #fff;
            border: 1px solid rgba(0, 0, 0, 0);
          }
          .avgRating {
            font-weight: 500;
            font-size: 22px;
            text-align: center;
            color: #fff;
          }
        }
      }
    }
  }
  .description {
    width: 57%;
    margin-top: 35px;
    font-size: 14px;
    line-height: 20px;
    text-align: left;
    color: #fff;
  }
  .price {
    margin-top: 10px;
    width: 57%;
    font-size: 12px;
    line-height: 20px;
    color: #808080;
  }
  .rate {
    margin-top: 25px;
    width: 100%;
    height: 40px;
    max-width: 320px;
    background: linear-gradient(
      90deg,
      rgba(0, 72, 255, 1) 0%,
      rgba(0, 238, 255, 1) 100%
    );
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 2px;
    button {
      width: 100%;
      max-width: 320px;
      text-transform: uppercase;
      height: 36px;
      border: 1px solid transparent;
      border-radius: 18px;
      background-color: black;
      color: #00eeff;
      font-weight: 700;
      outline: none;
    }
  }
  .filterRow {
    width: 57%;
    margin-top: 50px;
    display: flex;
    column-gap: 10px;
    .filter {
      font-size: 14px;
      line-height: 20px;
      text-align: left;
      color: #808080;
      width: 100px;
      padding: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 20px;
      border: 1px solid transparent;
      cursor: pointer;
    }

    .filterActive {
      border: 1px solid #808080;
    }
  }
}
