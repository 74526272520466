.message-list-container {
  padding: 10px;
  padding-bottom: 70px;
  margin-top: 105px;
}
.message-list {
  position: absolute;
  top: 108px;
  right: 0;
  /* bottom: 0; */
  left: 0;
  /* height: 100vh; */
}
