.postInput2 {
  width: "100%";
  margin: 0;
  display: block;
  padding: 6px 0 7px;
  padding: 9px;
}

.postInput2__highlighter {
  line-height: 1.2 !important;
  background-color: black;
  outline: none !important;
  border: none !important;
}
.postInput2__input {
  padding: 9px;
  border: none;
  outline: none;
  color: white;
  text-shadow: 1px 1px 1px black, 1px -1px 1px black, -1px 1px 1px black,
    -1px -1px 1px black;
}
.postInput2__suggestions {
  top: 15px !important;
  border-radius: 5px;
}

.input-mention2 {
  position: relative;
  z-index: 1;
  color: #0048ff;

  text-decoration: none;
  pointer-events: none;
}
