.wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
  column-gap: 20px;
  .searchInput {
    width: 100%;
    input {
      width: 100%;
      height: 45px;
      outline: none;
      font-size: 14px;
      border-top: 1px solid transparent;
      border-right: 1px solid transparent;
      border-left: 1px solid transparent;
      border-top: 1px solid transparent;
      color: white;
      background: url("../../../assets/images/search.svg") no-repeat scroll
        bottom 12px left 0px;
      padding: 10px 0 10px 25px;
      cursor: pointer;
    }

    input:focus {
      border-bottom: 1px solid #0048ff;
      font-weight: 300;
      font-size: 14px;
    }

    ::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: white;
    }
    :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: white;
    }
    ::-ms-input-placeholder {
      /* Microsoft Edge */
      color: white;
    }
  }
  .reset {
    height: 20px !important;
    width: 20px !important;
    fill: blue;
    path {
      stroke: blue;
    }
  }

  .filter {
    cursor: pointer;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      max-width: 100%;
      height: auto;
    }
  }
}

@media only screen and (min-width: 768px) {
  .wrapper {
    max-width: 1024px;
    display: flex;
    justify-content: flex-end;
    .searchInput {
      max-width: 250px;
    }
  }
}
