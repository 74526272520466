.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 20px;
  // border-bottom: 1px solid #333333;
  width: 100%;
  .interactions {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 20px;

    .item {
      display: flex;
      align-items: center;
      column-gap: 7px;
      .icon {
        cursor: pointer;
        img {
          max-width: 100%;
          height: 20px;
        }
        .img path {
          stroke: blue;
          stroke-width: 2px;
        }
      }
      .text {
        color: #808080;
        text-transform: uppercase;
        font-size: 16px;
      }
    }
  }
  .list_bar {
    width: 100%;
  }
}

@media only screen and (min-width: 767px) {
  .wrapper {
    // display: flex;
    // flex-direction: column;
    // align-items: center;
    // padding: 10px 20px;
    // border-bottom: 1px solid #333333;
    // width: 60%;
    // width: 800px;
    .interactions {
      justify-content: flex-end;
      border-bottom: 1px solid #333333;
      max-width: 40px;
      // max-width: calc(100% + 800px);
      // min-width: calc(100% + 800px);
      min-width: 930px;
    }
    .list_bar {
      width: 100%;
    }
  }
}
