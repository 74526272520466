.comment_wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 10px 20px;

  width: calc(100% + 40px);
}
/* .comment_wrapper_border {
  border-bottom: 1px solid #333333;
} */
.comment_top {
  border-top: 1px solid #333333;
}
.bar {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 20px;
}

.item {
  display: flex;
  align-items: center;
  column-gap: 7px;
}
.icon_sub_comment {
  max-width: 100%;
  height: 20px;
}
@media only screen and (min-width: 1200px) {
  .bar {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    column-gap: 20px;
  }

  /* .wrapper {
    // display: flex;
    // flex-direction: column;
    // align-items: center;
    // padding: 10px 20px;
    // border-bottom: 1px solid #333333;
    // width: calc(100% + 40px);
    // width: 1024px;
    .interactions {
      justify-content: flex-end;
      border-bottom: 1px solid #333333;
      max-width: 930px;
      // max-width: calc(100% + 800px);
      // min-width: calc(100% + 800px);
      min-width: 930px;
    }
  } */
}
