.highlight path {
  fill: white;
}
.StripeElement {
  margin-top: 17px !important;
  border-spacing: 10px !important;
  border-bottom: 1px solid #808080;
  padding-bottom: 10px;
}
.StripeElement--focus {
  border-bottom: 1px solid #0048ff !important;
}
