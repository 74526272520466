.container {
  border-top: 1px solid #808080;
  border-left: 1px solid #808080;
  border-right: 1px solid #808080;
  border-radius: 15px 15px 0px 0px;
  height: 200px;
  width: 200px;
  display: grid;
}
.footer {
  border: 1px solid #808080;
  border-radius: 0px 0px 15px 15px;
  height: 100px;
  width: 200px;
  display: grid;
}
.parent {
  position: relative;
  top: 0;
  left: 0;
}
.image1 {
  position: relative;
  top: 0;
  left: 0;
}
.image2 {
  position: absolute;
  top: 10px;
  left: 10px;
}
.centerItem {
  margin: auto;
}
.parentContainer {
  cursor: pointer;
}
.headerCorners {
  padding-left: 200px;
}
.buttonStyle {
  background-color: yellow;
  color: black;
  border: none;
  height: 40px;
  width: 90px;
  margin-left: 5px;
  border-radius: 10px 10px 10px 10px;
  margin-top: 15px;
}
