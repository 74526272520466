.wrapper {
  margin-top: 20px;
  width: 100%;
  max-width: 1024px;
  padding: 20px 0;
  border-bottom: 0.5px solid #333333;
  .reviewHeader {
    width: 100%;
    display: flex;
    justify-content: space-between;
    .reviewer {
      width: 100%;
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      column-gap: 10px;

      .info {
        display: flex;
        flex-direction: column;
        row-gap: 10px;
        .infoTop {
          display: flex;
          align-items: center;
          flex-wrap: nowrap;
          column-gap: 7px;
          .name {
            color: #808080;
            font-weight: 700;
            font-size: 14px;
          }
          img[alt="verified"] {
            max-width: 100%;
            height: auto;
          }
          .followers {
            font-weight: 700;
            color: #0048ff;
            font-size: 14px;
          }
        }
        .nickname {
          color: #737373;
          font-weight: 500;
          font-size: 14px;
        }
      }
    }
    .item {
      width: 70%;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      column-gap: 10px;
      .itemInfo {
        .name {
          font-weight: bold;
          font-size: 14px;
          text-align: left;
          color: #fff;
          margin-bottom: 5px;
        }
        .type {
          font-weight: normal;
          font-size: 14px;
          text-align: left;
          color: #808080;
          margin-bottom: 3px;
        }
        .date {
          font-weight: normal;
          font-size: 12px;
          text-align: left;
          color: #808080;
        }
      }
    }
    .item_success_rating {
      border: 1px solid #0f8;
    }
    .item_success_rating_danger {
      border: 1px solid rgb(219, 70, 44);
    }
    .itemRating {
      padding: 5px 20px;
      width: 80px;
      border-radius: 10px;
      background: transparent;
      display: flex;
      flex-direction: column;
      row-gap: 2px;
      align-items: center;
      justify-content: center;
      .ratingHeading {
        text-transform: uppercase;
        font-weight: normal;
        font-size: 12px;
        line-height: 20px;
        text-align: center;
        color: #fff;
        border: 1px solid rgba(0, 0, 0, 0);
      }
      .ratingPoints {
        font-weight: 500;
        font-size: 18px;
        text-align: center;
        color: #fff;
      }
    }
  }
  .description {
    width: 100%;
    margin-top: 20px;
    font-size: 14px;
    line-height: 20px;
    text-align: left;
    color: #fff;
  }

  .date {
    margin-top: 5px;
    width: 100%;
    font-size: 12px;
    line-height: 20px;
    color: #808080;
  }
  .deleteIcon {
    cursor: pointer;
    padding-left: 5px;
  }
  .editIcon {
    cursor: pointer;
    padding-top: 5px;
    padding-left: 5px;
  }
}
