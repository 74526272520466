.wrapper {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 20px 0 20px;

  .logo {
    margin-top: 40px;
  }

  .card {
    margin-top: 40px;
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    .main {
      max-width: 550px;
      width: 100%;
      form {
        width: 100%;
        input {
          width: 100%;
          background-color: transparent;
          outline: none;
          border-top: 1px solid transparent;
          border-right: 1px solid transparent;
          border-left: 1px solid transparent;
          border-bottom: 1px solid #808080;
          height: 50px;
          color: white;
          margin: 10px 0;
          padding: 0 5px;
          font-weight: 300;
          font-size: 14px;
        }
        ::-webkit-input-placeholder {
          /* Edge */
          color: #808080;
          font-family: Roboto;
          font-weight: 300;
          font-size: 14px;
        }
        :-ms-input-placeholder {
          /* Internet Explorer 10-11 */
          color: #808080;
          font-family: Roboto;
          font-weight: 300;
          font-size: 14px;
        }
        ::placeholder {
          color: #808080;
          font-family: Roboto;
        }
        input:focus {
          border-bottom: 1px solid #0048ff;
          font-weight: 300;
          font-size: 14px;
        }
        .row {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 10px;
          .remember {
            display: flex;
            align-items: center;
            column-gap: 10px;

            [type="radio"]:checked,
            [type="radio"]:not(:checked) {
              position: absolute;
              left: -9999px;
            }
            [type="radio"]:checked + label,
            [type="radio"]:not(:checked) + label {
              position: relative;
              padding-left: 25px;
              cursor: pointer;
              line-height: 20px;
              display: inline-block;
              color: #666;
              font-size: 14px;
            }
            [type="radio"]:checked + label:before,
            [type="radio"]:not(:checked) + label:before {
              content: "";
              position: absolute;
              left: 0;
              top: 0;
              width: 16px;
              height: 16px;
              border: 1px solid #808080;
              border-radius: 100%;
              background: black;
            }
            [type="radio"]:checked + label:after,
            [type="radio"]:not(:checked) + label:after {
              content: "";
              width: 10px;
              height: 10px;
              background: linear-gradient(
                90deg,
                rgba(0, 72, 255, 1) 0%,
                rgba(0, 238, 255, 1) 100%
              );
              position: absolute;
              top: 4px;
              left: 4px;
              border-radius: 100%;
              -webkit-transition: all 0.2s ease;
              transition: all 0.2s ease;
            }
            [type="radio"]:not(:checked) + label:after {
              opacity: 0;
              -webkit-transform: scale(0);
              transform: scale(0);
            }
            [type="radio"]:checked + label:after {
              opacity: 1;
              -webkit-transform: scale(1);
              transform: scale(1);
            }
          }
          .forget {
            color: #0048ff;
            cursor: pointer;
            font-weight: 300;
            font-size: 14px;
          }
        }
        .button {
          width: 100%;
          height: 40px;
          // background: linear-gradient(
          //   90deg,
          //   rgba(0, 72, 255, 1) 0%,
          //   rgba(0, 238, 255, 1) 100%
          // );
          border-radius: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0 2px;
          margin-top: 20px;
          button {
            width: 100%;
            text-transform: uppercase;
            height: 36px;
            border: 1px solid #00eeff;
            border-radius: 18px;
            background-color: black;
            color: white;
            background: linear-gradient(
              90deg,
              rgba(0, 72, 255, 1) 0%,
              rgba(0, 238, 255, 1) 100%
            );
            font-weight: 700;
            outline: none;
          }
        }
      }
    }
    .middle {
      max-width: 550px;
      margin-top: 40px;
      display: flex;
      flex-direction: column;
      align-items: center;
      row-gap: 20px;
      .socialText {
        width: 100%;
        text-align: center;
        color: #808080;
        font-size: 14px;
        font-weight: 400;
      }
      .socialIcons {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        column-gap: 15px;
        .icon {
          cursor: pointer;
          img {
            max-width: 100%;
            height: auto;
          }
        }
      }
    }
    .bottom {
      position: absolute;
      bottom: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      row-gap: 10px;
      margin-top: 60px;
      width: calc(100% + 40px);
      min-height: 100px;
      background: linear-gradient(
        90deg,
        rgba(0, 72, 255, 1) 0%,
        rgba(0, 238, 255, 1) 100%
      );
      .topText {
        color: white;
        text-align: center;
      }
      .bottomText {
        text-transform: uppercase;
        font-size: 20px;
        font-weight: 700;
      }
    }
    // .header {
    //     display: flex;
    //     flex-direction: column;
    //     align-items: center;
    //     justify-content: center;
    //   padding-left:10px ;
    //   width: 100%;
    //   border-top-left-radius: 10px;
    //   border-top-right-radius: 10px;
    //   height: 70px;
    //   background: linear-gradient(
    //     90deg,
    //     rgba(0, 72, 255, 1) 0%,
    //     rgba(0, 238, 255, 1) 100%
    //   );
    //   .Headtext {
    //     text-transform: uppercase;
    //     font-size: 20px;
    //     font-weight: 700;
    //   }
    // }
  }
}

@media only screen and (min-width: 1024px) {
  .wrapper {
    // background-image: url("../../assets/images/new_web.jpg");

    background-color: black;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;

    .card {
      background-color: black;
      border-radius: 10px;
      padding: 40px 40px 0 40px;
      max-width: 550px;
      max-height: 600px;
      box-shadow: 0px 34px 33px rgba(22, 28, 45, 0.13);
      margin-top: 20px;
      .bottom {
        position: relative;
        bottom: 0;
        width: 100%;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
      }
    }
  }
}

@media only screen and (min-width: 1200px) {
  .wrapper {
    // background-image: url("../../assets/images/new_web.jpg");
    .logo {
      position: absolute;
      margin-top: 0;
      top: 40px;
      left: 40px;
    }
    .card {
      position: absolute;
      //   right: 60px;
    }
  }
}

@media only screen and (min-width: 1440px) {
  .wrapper {
    .logo {
      position: absolute;
      margin-top: 0;
      top: 40px;
      left: 97px;
    }
    .card {
      //   position: absolute;
      //   right: 80px;
    }
  }
}
