.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.2);
}

.modal-main {
  position: fixed;
  background: black;
  width: 22%;
  height: 80vh;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  padding-top: 30px;
  padding-bottom: 30px;
  border-radius: 10px;
}

.display-block {
  display: block;
}

.display-none {
  display: none;
}
.newchat_title {
  font-weight: 600;
  font-size: 22px;
}
.emptyFriends {
  font-weight: lighter;
  font-size: 18px;
  // color: grey;
}
.emptySub {
  font-weight: lighter;
  font-size: 14px;
  color: grey;
}
.close-icon {
  height: 30px;
  width: 30px;
  // margin-top: 20px;
  position: absolute;
  top: 4px;
  right: 7px;
}
.empty-chat-container {
  margin-top: 30px;
  margin-bottom: 30px;
  // background: red;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.card-avatar {
  height: 50px;
  width: 50px;
  border-radius: 30px;
}
.right-arrow {
  height: 25px;
  width: 25px;
}
.chat-card-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  padding-top: 10px;
  padding-bottom: 10px;
  // background: red;
  margin: 0px 5px;
  justify-content: space-between;
}
.card-name {
  margin-right: 11px;
  margin-left: 17px;
}
.chat-card-sub {
  display: flex;
  align-items: center;
}
.modal-input {
  height: 161px;
  width: inherit;
}
.new-message-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.left-arrow {
  height: 20px;
  width: 20px;
  margin-right: 30px;
}
.new-message-header {
  display: flex;
  flex-direction: row;
  align-items: center;

  width: 95%;
}
.new-message-name {
  font-size: 20px;
  font-weight: bold;
}
.input-styles {
  margin: 20px 40px;
  background: black;
  outline: none;
  color: white;
  border-width: 0px;
  border-bottom-width: 1px;
}
.container-styles {
  min-height: 300px;
  overflow-y: scroll;
}
