.container {
  background-color: #262626;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 100;
  .wrapper {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    max-width: 1640px;

    .logo {
      display: flex;
      margin: 20px;
      img {
        max-width: 100%;
        width: 80px;
        height: 50px;
      }
      svg {
        max-width: 100%;
        // width: 80px;
        height: 61px;
      }

      // span {
      //   padding-top: 20px;
      //   margin-left: 10px;
      //   font-size: x-large;
      // }
    }

    .menuIcon {
      cursor: pointer;
      margin-right: 20px;
      img {
        max-width: 100%;
        height: auto;
      }
    }

    .menubar {
      display: none;
      margin-right: 20px;
    }
  }
}

@media only screen and (min-width: 600px) {
  .container {
    .wrapper {
      .logo {
        margin-left: 40px;
      }
      .menuIcon {
        margin-right: 40px;
      }
      .menubar {
        margin-right: 40px;
      }
    }
  }
}

@media only screen and (min-width: 1200px) {
  .container {
    .wrapper {
      //column-gap: 100px;
      column-gap: 50px;

      .logo {
        margin-left: 60px;
      }

      .menuIcon {
        display: none;
      }
      .menubar {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: nowrap;
        width: 100%;
        margin-right: 60px;
        .pageLinks {
          display: flex;
          flex-direction: row;
          align-items: center;
          column-gap: 20px;
          row-gap: 20px;

          .inactive {
            padding: 20px;
            display: flex;
            flex-direction: column;
            row-gap: 20px;
            align-items: center;
            border-top: 5px solid transparent;

            .text {
              text-transform: uppercase;
              color: #808080;
            }

            .homeIcon {
              width: 24.5px;
              height: 24.47px;
              background-image: url("../../../assets/images/home.svg");
              background-position: center;
              background-repeat: no-repeat;
              background-size: cover;
            }

            .exploreIcon {
              width: 24px;
              height: 23.92px;
              background-image: url("../../../assets/images/explore.svg");
              background-position: center;
              background-repeat: no-repeat;
              background-size: cover;
            }

            .reviewsIcon {
              width: 30px;
              height: 23px;
              background-image: url("../../../assets/images/reviews.svg");
              background-position: center;
              background-repeat: no-repeat;
              background-size: cover;
            }

            .questsIcon {
              width: 24.5px;
              height: 24.47px;
              background-image: url("../../../assets/images/quests.svg");
              background-position: center;
              background-repeat: no-repeat;
              background-size: cover;
            }

            .postIcon {
              width: 26px;
              height: 26px;
              background-image: url("../../../assets/images/post.svg");
              background-position: center;
              background-repeat: no-repeat;
              background-size: cover;
            }
          }

          .active {
            padding-top: 20px;
            color: #808080;
            display: flex;
            flex-direction: column;
            row-gap: 20px;
            align-items: center;
            border-top: 5px solid #0048ff;

            .text {
              text-transform: uppercase;
              color: #00eeff;
            }

            .homeIcon {
              width: 24.5px;
              height: 24.47px;
              background-image: url("../../../assets/images/home-active.svg");
              background-position: center;
              background-repeat: no-repeat;
              background-size: cover;
            }

            .exploreIcon {
              width: 24px;
              height: 23.92px;
              background-image: url("../../../assets/images/explore-active.svg");
              background-position: center;
              background-repeat: no-repeat;
              background-size: cover;
            }

            .reviewsIcon {
              width: 30px;
              height: 23px;
              background-image: url("../../../assets/images/reviews-active.svg");
              background-position: center;
              background-repeat: no-repeat;
              background-size: cover;
            }
            .questsIcon {
              width: 24.5px;
              height: 24.47px;
              background-image: url("../../../assets/images/quests-active.svg");
              background-position: center;
              background-repeat: no-repeat;
              background-size: cover;
            }
            .postIcon {
              width: 26px;
              height: 26px;
              background-image: url("../../../assets/images/post-active.svg");
              background-position: center;
              background-repeat: no-repeat;
              background-size: cover;
            }
          }
        }
        .otherLinks {
          display: flex;
          align-items: center;
          flex-wrap: nowrap;
          column-gap: 40px;
          .otherItem {
            color: white;
            text-decoration: none;
            position: relative;
            display: inline-block;
            border-radius: 2px;
            .itemIcon {
              img {
                max-width: 100%;
                height: auto;
              }
            }
          }
          .profile {
            background-size: cover;
            .profileImage {
              img {
                border-radius: 50%;
              }
            }
          }
        }
      }
    }
  }
}
.counter {
  position: absolute;
  top: -10px;
  right: -10px;
  padding: 5px 10px;
  border-radius: 50%;
  background: blue;
  color: white;
}
