.dialog_wrapper {
  column-gap: 29px !important;
}
.dialog_item {
  width: 80px;
  height: 70px;

  /* padding: 5px; */
  display: flex;
  align-items: center;
  justify-content: center;
}
.dialog_item_Border {
  border-radius: 14px;
  margin-top: 17px;
  border: 1px solid gray;
}
.home__icon path {
  fill: black;
}
/* .MuiSvgIcon-root {
  transform: scale(1.3);
} */
.icon_size {
  transform: scale(1.5);
}
.privacy {
  display: flex;
  justify-content: space-between;
  transform: scale(0.9);
}

.postInput {
  width: 45ch;
  margin: 0;
  display: block;
  padding: 6px 0 7px;
  padding: 9px;
}

.postInput__highlighter {
  line-height: 1.15 !important;
}
.postInput__input {
  padding: 9px;
  border: none;
  border-bottom: 1px solid;
  outline: none;
}
.postInput__suggestions {
  top: 15px !important;
  z-index: 999 !important;
  max-height: 150px !important;
  overflow: scroll !important;
}
.postInput__suggestions__list {
  max-height: 150px !important;
  overflow: scroll !important;
}

.input-mention {
  position: relative;
  z-index: 1;
  color: #0048ff;
  text-shadow: 1px 1px 1px white, 1px -1px 1px white, -1px 1px 1px white,
    -1px -1px 1px white;
  text-decoration: none;
  pointer-events: none;
}

@media screen and (max-width: 877px) {
  .MuiDialog-paperWidthSm {
    max-width: 600px !important;
  }
}
