.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
  .text {
    width: 100%;
    margin: 20px 0 5px 0;
    font-size: 14px;
    max-width: 1024px;
    display: block;
    text-overflow: ellipsis;
    word-wrap: break-word;
    overflow: hidden;
    max-height: 4.5em;
    line-height: 1.5em;
  }

  form {
    width: 100%;
    max-width: 1024px;
    .poll {
      width: 100%;
      max-width: 1024px;
      .option {
        display: flex;
        align-items: center;
        column-gap: 10px;
        margin: 10px 0;

        [type="radio"]:checked,
        [type="radio"]:not(:checked) {
          position: absolute;
          left: -9999px;
        }
        [type="radio"]:checked + label,
        [type="radio"]:not(:checked) + label {
          position: relative;
          padding-left: 25px;
          cursor: pointer;
          line-height: 20px;
          display: inline-block;
          color: white;
          font-size: 14px;
        }
        [type="radio"]:checked + label:before,
        [type="radio"]:not(:checked) + label:before {
          content: "";
          position: absolute;
          left: 0;
          top: 0;
          width: 16px;
          height: 16px;
          border: 1px solid #808080;
          border-radius: 100%;
          background: black;
        }
        [type="radio"]:checked + label:after,
        [type="radio"]:not(:checked) + label:after {
          content: "";
          width: 10px;
          height: 10px;
          background: linear-gradient(
            90deg,
            rgba(0, 72, 255, 1) 0%,
            rgba(0, 238, 255, 1) 100%
          );
          position: absolute;
          top: 4px;
          left: 4px;
          border-radius: 100%;
          -webkit-transition: all 0.2s ease;
          transition: all 0.2s ease;
        }
        [type="radio"]:not(:checked) + label:after {
          opacity: 0;
          -webkit-transform: scale(0);
          transform: scale(0);
        }
        [type="radio"]:checked + label:after {
          opacity: 1;
          -webkit-transform: scale(1);
          transform: scale(1);
        }
      }
    }
    .bottom {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      margin: 15px 0;

      .bWrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        row-gap: 15px;
        width: 100%;
        .button {
          width: 100%;
          height: 40px;
          max-width: 600px;
          background: linear-gradient(
            90deg,
            rgba(0, 72, 255, 1) 0%,
            rgba(0, 238, 255, 1) 100%
          );
          border-radius: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0 2px;
          margin-top: 10px;
          button {
            width: 100%;
            max-width: 600px;
            text-transform: uppercase;
            height: 36px;
            border: 1px solid transparent;
            border-radius: 18px;
            background-color: black;
            color: #00eeff;
            font-weight: 700;
            outline: none;
          }
        }
        .time {
          font-size: 12px;
          color: #808080;
        }
      }
    }
  }
}

@media only screen and (min-width: 1200px) {
  .wrapper {
    form {
      .bottom {
        align-items: flex-start;
        max-width: 300px;

        .bWrapper {
          .button {
            button {
            }
          }
        }
      }
    }
  }
}
