.parent {
  margin-left: 30%;
}
.title {
  color: white;
}
.body {
  color: #868686;
}
.iconStyle {
  color: white;
}
.header {
  color: #868686;
}
