.imgStyle {
  margin-right: 10px;
  height: 20px;
  width: 20px;
}
.parent {
  position: relative;
  top: 0;
  left: 0;
}
.image1 {
  position: relative;
  top: 0;
  left: 0;
}
.image2 {
  position: absolute;
  top: 5%;
  bottom: 5%;
  left: 5%;
  right: 5%;
}
.image3 {
  position: relative;
  top: 0;
  left: 0;
}
