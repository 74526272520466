.frameStyle {
  width: 60%;
  height: 100% !important;
}
.fill {
  border: 1px solid #808080;
  border-radius: 15px 15px 15px 15px;
  height: 400px;
  width: 320px;
  cursor: pointer;
}
.fill .pic {
  width: 100%;
  height: 300px;
  // border-top: 15px 15px 15px 15px;
  border-radius: 15px 15px 0px 0px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  // background-color: #4b4646ea;
  // background-image: url("../../../assets/images/search.svg");
}
.addIcon {
  width: 60%;
  height: auto;
  border: 1px solid #808080;
  border-radius: 15px 15px 15px 15px;
}
.iconPosition {
  margin-top: 60%;
  margin-left: 40%;
}
.imageFooter {
  border-left: 1px solid #808080;
  border-right: 1px solid #808080;
  border-bottom: 1px solid #808080;
  border-radius: 15px 15px 15px 15px;
}
.profilePic .pic {
  width: 100%;
  height: 300px;
  // border-top: 15px 15px 15px 15px;
  border-radius: 15px 15px 0px 0px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  // background-color: #4b4646ea;
  width: 100%;
  height: 400px;
  border-top: 15px 15px 15px 15px;
  border-radius: 15px 15px 15px 15px;
  cursor: pointer;
}
.addBackground {
  border: 1px solid #808080;
  border-radius: 15px 15px 15px 15px;
  height: 400px;

  cursor: pointer;
  display: grid;
}
.addBackground div {
  margin: auto;
}
