.content-toolbar {
  position: fixed !important;
  /* left: calc(100% - 20%);
  right: 0; */
 
  display: flex;
  align-items: center;
right: 9px;
left: 351px;
  background-color: black;
  font-weight: 500;
  border-bottom: 1px solid #eeeef1;
}

 @media only screen and (min-width: 1580px) {
  .content-toolbar {
    
    right: 20px;
  }
}
/*
@media only screen and (min-width: 920px) {
  .content-toolbar {
    width: calc(100% - 40%) !important;
  }
}

@media only screen and (min-width: 1200px) {
  .content-toolbar {
    width: calc(100% - 40%) !important;
  }
}
@media only screen and (min-width: 1300px) {
  .content-toolbar {
    width: calc(100% - 28%) !important;
  }
}
@media only screen and (min-width: 1450px) {
  .content-toolbar {
     width: calc(100% - 23%) !important;
  }
}
@media only screen and (min-width: 1750px) {
  .content-toolbar {
    left: calc(100% - 80%) !important;
  }
}
@media only screen and (min-width: 1850px) {
  .content-toolbar {
    left: calc(100% - 81.5%) !important;
  }
}
@media only screen and (max-width: 764px) {
  .content-toolbar {
    left: calc(100% - 60%);
  }
} */

@supports (backdrop-filter: blur(20px)) {
  .content-toolbar {
    border: none;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(20px);
  }
}

.content-toolbar-title {
  margin: 0;
  font-size: 20px;
  font-weight: 800;
  color: white;
  text-transform: capitalize;
}

.content-left-items,
.content-right-items {
  flex: 1;
  padding: 10px;
  display: flex;
}

.content-right-items {
  flex-direction: row-reverse;
}

.content-left-items .toolbar-button {
  margin-right: 10px;
}

.content-right-items .toolbar-button {
  margin-left: 20px;
}

.content-left-items .toolbar-button:last-child,
.content-right-items .toolbar-button:last-child {
  margin: 0;
}
