.modal {
  background-color: #000000;
  border-color: #03adfc;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: "scroll";
  width: 100%;
}
.divTextField {
  width: 50%;
}
.divTextField2 {
  width: 47%;
}
.addIcon {
  margin-top: 10px;
  color: rgb(128, 128, 128);
  cursor: pointer;
}
.listItemContainer {
  margin-top: 10px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.listItem {
  border: 2px solid rgb(128, 128, 128);
  color: rgb(128, 128, 128);
  width: max-content;
  padding: 7px;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 5px;
  margin-top: 5px;
}
.crossIcon {
  height: 16px;
  width: 16px;
  cursor: pointer;
}
.userAvatar {
  margin-top: 15px !important;
}
.submitbutton {
  width: 50%;
  height: 40px;
  margin-top: 60px !important;
  border: 2px solid #2196f3 !important;
  border-radius: 50px 50px 50px 50px !important;
}
.disablebutton {
  width: 50%;
  height: 40px;
  margin-top: 50px !important;
  border: 2px solid #808080 !important;
  color: #808080 !important;
  border-radius: 50px 50px 50px 50px !important;
}
.backButton {
  color: #808080 !important;
  margin-left: 175px !important;
}
.iconStyle {
  width: 40px !important;
  height: 40px !important;
  margin-top: 20px !important;
}
.parent {
  position: relative;
  top: 0;
  left: 0;
}
.image1 {
  position: relative;
  top: 0;
  left: 0;
}
.image2 {
  position: absolute;
  top: 22px;
  left: 45%;
}
.image3 {
  position: relative;
  top: 0;
  left: 0;
}
@media (max-width: 1950px) {
  .image2 {
    position: absolute;
    top: 22px;
    left: 46% !important;
  }
}
@media (max-width: 1850px) {
  .image2 {
    position: absolute;
    top: 22px;
    left: 46% !important;
  }
}
@media (max-width: 1750px) {
  .image2 {
    position: absolute;
    top: 22px;
    left: 46% !important;
  }
}
@media (max-width: 1700px) {
  .image2 {
    position: absolute;
    top: 22px;
    left: 45.5% !important;
  }
}
@media (max-width: 1600px) {
  .image2 {
    position: absolute;
    top: 22px;
    left: 45% !important;
  }
}
@media (max-width: 1336px) {
  .image2 {
    position: absolute;
    top: 22px;
    left: 43% !important;
  }
}
@media (max-width: 1024px) {
  .image2 {
    position: absolute;
    top: 22px;
    left: 43% !important;
  }
}
@media (max-width: 768px) {
  .image2 {
    position: absolute;
    top: 22px;
    left: 40% !important;
  }
  .backButton {
    margin-left: 65px !important;
  }
}
@media (max-width: 640px) {
  .image2 {
    position: absolute;
    top: 22px;
    left: 30% !important;
  }
}
@media (max-width: 540px) {
  .image2 {
    position: absolute;
    top: 22px;
    left: 35% !important;
  }
  .backButton {
    margin-left: 32px !important;
  }
}

@media (max-width: 425px) {
  .image2 {
    position: absolute;
    top: 22px;
    left: 33% !important;
  }
  .backButton {
    margin-left: 27px !important;
  }
}
@media (max-width: 414px) {
  .image2 {
    position: absolute;
    top: 22px;
    left: 32% !important;
  }
  .backButton {
    margin-left: 25px !important;
  }
}
@media (max-width: 411px) {
  .image2 {
    position: absolute;
    top: 22px;
    left: 32% !important;
  }
  .backButton {
    margin-left: 25px !important;
  }
}
@media (width: 375px) {
  .image2 {
    position: absolute;
    top: 22px;
    left: 29% !important;
  }
  .backButton {
    margin-left: 10px !important;
  }
}
@media (max-width: 360px) {
  .image2 {
    position: absolute;
    top: 22px;
    left: 28% !important;
  }
  .backButton {
    margin-left: 10px !important;
  }
}
@media (max-width: 320px) {
  .image2 {
    position: absolute;
    top: 22px;
    left: 26% !important;
  }
  .backButton {
    margin-left: 10px !important;
  }
}

@media (max-width: 280px) {
  .image2 {
    position: absolute;
    top: 22px;
    left: 21% !important;
  }
  .backButton {
    margin-left: 10px !important;
  }
}
