@import-normalize;
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap");
html,
body {
  padding: 0;
  margin: 0;
  font-family: Roboto, sans-serif;
  font-weight: 400;
  scroll-behavior: smooth;
  background-color: black;
  color: white;
}
a {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}

* {
  box-sizing: border-box;
}

button {
  cursor: pointer;
}
