.icon path {
  stroke: black;
  font-size: 20px;
}
.icon svg {
  font-size: 2px i !important;
}
.icon rect {
  display: none;
}
