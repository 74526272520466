.sidebar {
  height: 100%;
  transition: width 0.5s;
  width: 0;
  position: fixed;
  top: 0;
  z-index: 1;
  right: 0;
  /* overflow: hidden; */
  background-color: black;
  /* display: none; */
}

@media only screen and (max-width: 1200px) {
  .block {
    height: 100%;
    transition: width 0.5s;
    width: 100% !important;
    /* width: 25rem; */
    position: fixed;
    top: 0;
    z-index: 1;
    right: 0;
    overflow: hidden;
    background-color: black;
  }
}
.block {
  height: 100%;
  transition: width 0.5s;
  display: flex;
  justify-content: center;
  /* width: 200px !important; */
  /* width: 25rem; */
  position: fixed;
  top: 0;
  z-index: 1;
  right: 0;
  overflow: hidden;
  /* #0000009c */
  background-color: #0000009c;
}
.left {
  position: absolute;
  top: 0;
  bottom: 0;
  /* background: black; */
  left: 0;
  width: 40%;
}
.right-menu {
  position: absolute;
  top: 0;
  bottom: 0;
  background: black;
  right: 0;
  left: 44%;
}
.right-close{
  display: none;
}
.close-image-icon {
  height: 30px;
  width: 30px;
  position: absolute;
  top: 13px;
  /* left: 8px; */
  right: 14px;
}
.menu {
  margin-top: 30px;
}
.inactive {
  padding: 20px;
  display: flex;
  flex-direction: row;
  row-gap: 20px;
  align-items: center;
  border-top: 5px solid transparent;
  cursor: pointer;
}

.text {
  margin-left: 10px;
  text-transform: uppercase;
  color: #808080;
}

.homeIcon {
  width: 24.5px;
  height: 24.47px;
  background-image: url("../../../assets/images/home.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.exploreIcon {
  width: 24px;
  height: 23.92px;
  background-image: url("../../../assets/images/explore.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.reviewsIcon {
  width: 30px;
  height: 23px;
  background-image: url("../../../assets/images/reviews.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.questsIcon {
  width: 24.5px;
  height: 24.47px;
  background-image: url("../../../assets/images/quests.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.postIcon {
  width: 26px;
  height: 26px;
  display: inline-block;
  background-image: url("../../../assets/images/post.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.customizeIcon {
  width: 26px;
  height: 26px;
  display: inline-block;
  background-image: url("../../../assets/images/profile-customize.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.settingIcon {
  width: 26px;
  height: 26px;
  display: inline-block;
  background-image: url("../../../assets/images/profile-settings.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}