.parent {
  position: absolute;
  background: #262626;
  margin-left: 30%;
  margin-right: 30%;
  width: 40%;
  margin-top: 5%;
}
.title {
  color: white;
}
.body {
  color: #868686;
}
.iconStyle {
  color: white;
}
