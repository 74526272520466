.submitButton {
  width: 80%;
  margin-right: 10% !important;
  border-radius: 15px 15px 15px 15px !important;
  height: 40px;
  border: 3px solid #2196f3 !important;
  color: #2196f3 !important;
  margin-top: 15px;
}
.disableButton {
  width: 80%;
  margin-right: 10% !important;
  border-radius: 15px 15px 15px 15px;
  height: 40px;
  border: 1px solid #868686;
  margin-top: 15px;
}
.sliderValue {
  float: right;
  margin-right: 10%;
  color: #e37c2d;
}
