.compose {
  padding: 10px;
  display: flex;
  align-items: center;
  background: white;
  border-top: 1px solid #eeeef1;
  position: fixed;
  width: 80%;
  bottom: 0px;
  /* left: 0; */
  right: 0;
  margin: 4px 20px;
}

@media only screen and (min-width: 920px) {
  .compose {
    width: 60% !important;
  }
}
@media only screen and (min-width: 764px) {
  .compose {
    width: 50%;
  }
}
@media only screen and (min-width: 1200px) {
  .compose {
    width: 70% !important;
  }
}
@media only screen and (min-width: 1450px) {
  .compose {
    width: 75% !important;
  }
}
@media only screen and (min-width: 1750px) {
  .compose {
    width: 78% !important;
  }
}
@media only screen and (min-width: 1850px) {
  .compose {
    width: 80% !important;
  }
}
@media only screen and (max-width: 764px) {
  .compose {
    width: 50%;
  }
}
@supports (backdrop-filter: blur(20px)) {
  .compose {
    border: none;
    background-color: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(20px);
  }
}

.compose-input {
  flex: 1;
  border: none;
  font-size: 14px;
  height: 40px;
  background: none;
  outline: none;
}

.compose-input::placeholder {
  opacity: 0.3;
}

.compose .toolbar-button {
  color: #bbbbbf;
  margin-left: 15px;
}

.compose .toolbar-button:hover {
  color: #99999c;
}
