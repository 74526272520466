.textfield {
  width: 200px !important;
}
.submitButton {
  border: 3px solid #2196f3;
  border-radius: 15px 15px 15px 15px;
  height: 40px;
}
.disabledButton {
  border: 3px solid #868686;
  border-radius: 15px 15px 15px 15px;
  height: 40px;
}
.progressBar {
  color: #2196f3 !important;
  margin-left: 2% !important;
}
