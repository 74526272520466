.body {
  margin: 0;
  padding: 0;
}

@media only screen and (min-width: 768px) {
  .wrapper {
    background-image: url("../../assets/images/new_web.jpg");
    width: 100%;
    height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    display: grid;
  }
  .heading {
    margin-left: auto;
    margin-right: 0px;
    font-size: 50px;
    margin-bottom: 15px;
  }
  .heading_one {
    margin-left: auto;
    margin-right: 0px;
    font-size: 40px;
    margin-bottom: 15px;
    font-weight: 300;
    margin-top: 50%;
  }
  .subheading {
    margin-left: auto;
    margin-right: 0px;
    font-size: 20px;
    margin-top: 0px;
    margin-bottom: 15px;
    font-weight: 300;
  }
  .downlaod {
    height: 40px;
    background: linear-gradient(
      90deg,
      rgba(0, 72, 255, 1) 0%,
      rgba(0, 238, 255, 1) 100%
    );
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    color: white;
    padding: 0 2px;
    margin-right: 30px;
    outline: none;
  }
  .register {
    height: 40px;
    background: linear-gradient(
      90deg,
      rgba(0, 72, 255, 1) 0%,
      rgba(0, 238, 255, 1) 100%
    );
    border-radius: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    color: white;
    padding: 0 2px;
    margin-right: 30px;
    outline: none;
  }
  .button {
    height: 63px;
    background: linear-gradient(
      90deg,
      rgba(0, 72, 255, 1) 0%,
      rgba(0, 238, 255, 1) 100%
    );
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 2px;
    margin-right: 30px;
    outline: none;
    .login {
      text-transform: uppercase;
      height: 60px;
      border: 1px solid transparent;
      border-radius: 18px;
      background-color: black;
      color: #00eeff;
      font-weight: 700;
      outline: none;
    }
  }

  .container {
    // width: 30%;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: 8%;
    display: grid;
    .socialNetwork {
      line-height: 30px;
      margin-left: auto;
      margin-right: 0;
      // display: flex;
      .mid {
        text-align: right;
        .bold {
          font-size: 45px;
          font-weight: 400;
          // fontSize: "55px",
          // fontWeight: "500",
          // display: "flex",
          // justifyContent: "flex-end",
        }
      }
      .register {
        width: 370px;
        height: 60px;
        margin-right: -1%;
        margin-top: 10%;
        // margin-left: auto;
      }
    }
    .div {
      margin-left: auto;
      margin-right: -4%;
      display: flex;

      .div1 {
        button {
          width: 180px;
          height: 60px;
          margin: 0px;
        }
        .downlaod {
          margin-right: 70px;
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .wrapper {
    background-image: url("../../assets/images/mobile.jpg");
    width: 100%;
    height: 100vh;
    background-position: 32% 0px;
    background-size: cover;
    background-repeat: no-repeat;
    position: absolute;
  }
  .heading {
    margin-left: auto;
    margin-right: 0px;
    font-size: 6ch;
    text-align: center;
    // margin-bottom: 15px;
  }
  .subheading {
    // margin-left: auto;
    // margin-right: 0px;
    text-align: center;
    font-size: 16px;
    // margin-top: 0px;
    margin-bottom: 15px;
    font-weight: 300;
  }
  .downlaod {
    height: 40px;
    background: linear-gradient(
      90deg,
      rgba(0, 72, 255, 1) 0%,
      rgba(0, 238, 255, 1) 100%
    );
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    color: white;
    padding: 0 2px;
    margin-right: 30px;
    outline: none;
  }
  .register {
    height: 40px;
    background: linear-gradient(
      90deg,
      rgba(0, 72, 255, 1) 0%,
      rgba(0, 238, 255, 1) 100%
    );
    border-radius: 36px;
    // display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    color: white;
    padding: 0 2px;
    // margin-right: 30px;
    outline: none;
  }
  .heading_one {
    font-size: 37px;
    margin-bottom: 17px;
    font-weight: 300;
    text-align: center;
    padding: 0px 14px;
  }
  .button {
    height: 63px;
    background: linear-gradient(
      90deg,
      rgba(0, 72, 255, 1) 0%,
      rgba(0, 238, 255, 1) 100%
    );
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    // padding: 0 2px;
    margin-right: 30px;
    outline: none;
    .login {
      text-transform: uppercase;
      height: 60px;
      border: 1px solid transparent;
      border-radius: 18px;
      background-color: black;
      color: #00eeff;
      font-weight: 700;
      outline: none;
      display: none;
    }
  }
  .container {
    width: 100vw;
    position: absolute;
    bottom: 4ch;
    .socialNetwork {
      // line-height: 30px;
      width: 100%;
      margin-right: 0;
      display: flex;
      justify-content: center;
      .mid {
        text-align: center;
        .bold {
          font-size: 37px;
          font-weight: 400;
          // fontSize: "55px",
          // fontWeight: "500",
          // display: "flex",
          // justifyContent: "flex-end",
        }
      }

      .register {
        width: 300px;
        height: 60px;
        // margin-right: -1%;
        margin-top: 10%;
        // margin-left: auto;
      }
    }
    .div {
      // margin-left: auto;
      margin-right: 0px;
      display: flex;
      justify-content: center;
      text-align: center;
      .div1 {
        button {
          width: 130px;
          height: 60px;
          margin: 0px;
          // display: none;
        }
        .downlaod {
          margin-right: 5px;
        }
      }
    }
  }
}
// @media only screen and (max-width: 1024px) {
//   .container {
//     width: 30%;
//     margin-top: auto;
//     margin-bottom: auto;
//     margin-left: auto;
//     margin-right: 20%;
//   }
// }
