.mention {
  color: #101ff0;
  text-decoration: none;
}

.mentionSuggestions {
  background: black;
  border-radius: 2px;
  cursor: pointer;
  padding-top: 8px;
  padding-bottom: 8px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  transform-origin: 50% 0%;
  transform: scaleY(0);
  width: 400px;
  margin: 12px;
  z-index: 2;
  box-shadow: 0px 0px 2px gray;
}

.mentionSuggestionsEntryContainer {
  display: table;
  width: 100%;
}

.mentionSuggestionsEntryContainerLeft,
.mentionSuggestionsEntryContainerRight {
  display: table-cell;
  vertical-align: center;
}

.mentionSuggestionsEntryContainerRight {
  width: 100%;
  padding-left: 8px;
}

.mentionSuggestionsEntry {
  padding: 7px 10px 3px 10px;
  border-bottom: 1px solid gray;
  transition: background-color 0.4s cubic-bezier(0.27, 1.27, 0.48, 0.56);
}

.mentionSuggestionsEntry:active {
  background-color: #cce7ff63;
}

.mentionSuggestionsEntryFocused {
  composes: mentionSuggestionsEntry;
  background-color: #cce7ff63;
}

.mentionSuggestionsEntryText,
.mentionSuggestionsEntryTitle {
  /* white-space: nowrap; */
  /* overflow: hidden; */
  text-overflow: visible;

  position: absolute;
}

.mentionSuggestionsEntryText {
  width: 30px;
  height: 30px;
}

.mentionSuggestionsEntryTitle {
  font-size: 80%;

  color: red;
}

.mentionSuggestionsEntryAvatar {
  display: inline-block;
  margin-right: 20px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
}
