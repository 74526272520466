.header {
  width: 60%;
}
.footer {
  margin-left: 17%;
  margin-top: 7% !important;
  display: flex;
  flex-direction: row;
}
.submit {
  margin-left: 30% !important;
  margin-top: 20px;
  margin-bottom: 20px;
  height: 40px;
  width: 25%;
  border: 3px solid #2196f3;
  border-image-slice: 1 !important;
  border-color: linear-gradient(45deg, #2196f3 30%, #21cbf3 90%) !important;
  border-radius: 100px 100px 100px 100px;
}
.cancel {
  margin-top: 20px;
  margin-bottom: 20px;
  height: 40px;
  width: 25%;
  border: 3px solid #2196f3;
  border-image-slice: 1 !important;
  border-color: linear-gradient(45deg, #2196f3 30%, #21cbf3 90%) !important;
  border-radius: 100px 100px 100px 100px;
}
.progressBar {
  color: #2196f3 !important;
  margin-left: 2% !important;
}
