.editor {
  border: 1px solid gray !important;
  /* height: 37px; */
  display: flex;
  align-items: flex-start;
  /* padding: 5px 4px; */
  padding-left: 15px;
  /* margin-right: 5px; */
  padding-bottom: 10px;
  border-radius: 20px;
  padding-top: 10px;
  color: gray;
  display: inline-block;
  box-sizing: content-box;
  min-width: 92%;
  width: 96%;
}
