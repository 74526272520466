.wrapper {
  width: 100%;
  max-width: 1024px;
  display: flex;
  align-items: center;
  flex-direction: column;
  .header {
    width: 100%;
    min-height: 500px;
    max-height: 500px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    padding: 20px;
    position: relative;
    .headerContent {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      position: relative;
      margin-top: 100px;

      .levelBar {
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
        column-gap: 10px;
        margin-top: 20px;
        .coins {
          display: flex;
          flex-wrap: nowrap;
          align-items: center;
          column-gap: 10px;
          .coinIcon {
            cursor: pointer;
            img {
              max-width: 100%;
              height: auto;
            }
          }
          .coinText {
            font-size: 14px;
            font-weight: 500;
          }
        }
        .progressBar {
          min-width: 150px;
          max-width: 170px;
          height: 15px;
          border-radius: 3px;
          background: #0d32e670;
        }
        .xpPoints {
          font-weight: bold;
          font-size: 14px;
          line-height: 20px;
          text-align: center;
          color: #fff;
        }
      }

      .userInfo {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: nowrap;
        margin-top: 18%;

        .userDetails {
          .nameWrapper {
            display: flex;
            align-items: center;
            flex-wrap: nowrap;
            column-gap: 10px;
            .username {
              font-weight: bold;
              font-size: 16px;
              line-height: 24px;
              text-align: left;
              color: #a29f99b3;
              margin-bottom: 3px;
            }
          }

          .nickname {
            font-weight: normal;
            font-size: 14px;
            line-height: 20px;
            text-align: left;
            color: #808080;
          }
          .itemDate {
            font-weight: normal;
            font-size: 14px;
            line-height: 20px;
            text-align: left;
            color: #808080;
          }
        }
        .level {
          padding: 10px;
          width: 70px;
          height: 63px;
          border-radius: 10px;
          background: transparent;
          border: 1px solid #0048ff;
          display: flex;
          flex-direction: column;
          row-gap: 2px;
          align-items: center;
          justify-content: center;
          .levelHeading {
            text-transform: uppercase;
            font-weight: normal;
            font-size: 12px;
            line-height: 20px;
            text-align: center;
            color: #0048ff;
            border: 1px solid rgba(0, 0, 0, 0);
          }
          .currentLevel {
            font-weight: 500;
            font-size: 22px;
            text-align: center;
            color: #0048ff;
          }
        }
      }
    }
  }
}
.omegastore {
  margin: auto;
  cursor: pointer;
  color: yellow;
  outline: none;
}
.parent {
  position: relative;
  top: 0px;
  left: 0;
}
.image1 {
  position: relative;
  top: 0;
  left: 0;
}
.image2 {
  position: absolute;
  top: 22px;
  left: 24px;
}
.image3 {
  position: relative;
  top: 0;
  left: 0;
}
.alignStore {
  display: grid !important;
}
