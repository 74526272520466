.notification_container {
  display: flex;
  flex-direction: column;
  height: 100%;
  /* background: red; */
  align-self: center;
  margin-left: auto;
  margin-right: auto;
  width: 70%;
  cursor: pointer;
}
.notifications_containerTop {
  /* height: 50%; */
  /* background: red; */
  flex: 1;
}
.notification_followRequest {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.notification_title {
  text-transform: uppercase;
  font-weight: 400;
  font-size: 18;
  color: rgba(255, 255, 255, 0.7);
}
.notifications_text {
  font-weight: lighter;
  font-size: 16;
  color: rgba(255, 255, 255, 0.7);
}
.notification_cardTitle {
  font-weight: 300;
  font-size: 16px;
  margin-right: 30px;
}
.notification_cardContainer {
  display: flex;
  flex-direction: row;
  /* background: red; */
  width: 80%;
  align-items: center;
  border-bottom: 1px solid rgba(255, 255, 255, 0.4);
  padding-bottom: 20px;
  padding-top: 20px;
}
.notification_avatar {
  background-size: cover;
}
.notification_date {
  text-transform: lowercase;
  font-weight: lighter;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.5);
}
/* .notification_button {
  height: 35px;
  width: 100px;
  margin-right: 10px;
} */
.notification_buttonContainer {
  /* background: greenyellow; */
  justify-content: space-around;
  align-items: center;
  display: flex;
  flex-direction: row;
}

.notification_button {
  background-image: linear-gradient(
    to right,
    #0049ff 0%,
    #02eeff 51%,
    #02eeff 100%
  );
}
.notification_button {
  /* margin: 10px; */
  padding: 13px 30px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  /* box-shadow: 0 0 20px #eee; */
  border-radius: 50px;
  display: block;
  text-decoration: none;
  border-width: 0px;
  outline: 0 !important;
}

.notification_button:hover {
  background-position: right center; /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}

.notification_buttonDeny {
  border-radius: 100rem;
  padding: 8px 35px;
  font-family: "Avenir Next";
  font-size: 14px;
  text-transform: uppercase;
  color: #fff;
  border: solid 3px transparent;
  background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)),
    linear-gradient(to left, #0049ff, #02eeff);
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 2px 100px 1px rgb(0, 0, 0) inset;
  outline: 0 !important;
}
