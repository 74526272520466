.terms {
  color: blue;
}
.exposeButton {
  width: 20%;
  margin-left: 10%;
  border-radius: 15px 15px 15px 15px;
  height: 40px;
  border: 3px solid #2196f3;
}
