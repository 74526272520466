.conversation-list {
  display: flex;
  flex-direction: column;
  margin-top: 6px;
}
@media only screen and (min-width: 764px) {
  .conversation-list {
    margin-top: 15px;
    width: 294px !important;
  }
}

@media only screen and (min-width: 864px) {
  .conversation-list {
    margin-top: 35px !important;
    width: inherit !important;
  }
}
