.dot {
  height: 25px;
  width: 25px;
  border-radius: 50%;
  display: inline-block;
  margin: 10px !important;
  cursor: pointer;
}
.grayIcon {
  background-color: #666666;
}
.redIcon {
  background-color: #ff1a4a;
}
.yellowIcon {
  background-color: #ffd949;
}
.greenIcon {
  background-color: #00ff88;
}
.blueIcon {
  background-color: #02eeff;
}
.purpleIcon {
  background-color: #0049ff;
}
.pinkIcon {
  background-color: #ff03f7;
}
.addIcon {
  color: white;
}
.whiteCircle {
  height: 25px;
  width: 25px;
  border-radius: 50%;
  margin: auto;
  border: 1px solid white;
}
.blueCircle {
  height: 100px;
  width: 100px;
  border-radius: 50%;
  display: inline-block;
  border: 1px solid blue;
  cursor: pointer;
  display: grid;
}
.cornerPointer {
  cursor: pointer;
  display: flex;
  padding-left: 10px;
}
