.homeCard{
    border: 1px solid blue;
    padding: 5px;
    /* height: 40vh; */
    /* width: 20%; */
    /* border-radius: 13px; */
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}
.btn-sub{
background-color: transparent;
color: white;
border: 1px solid lightblue;
border-radius: 5px;
font-size: large;
padding: 10px;
margin-bottom: 5px;
}
@media(max-width:728px){
    .btn-sub{
        background-color: transparent;
        color: white;
        border: 1px solid lightblue;
        border-radius: 5px;
        font-size: 14px;
        padding: 3px;
        }  
}
.btn-sub:hover{
    background-image: linear-gradient(to left, rgb(147, 206, 240), rgb(88, 181, 235));;
    color: white;
}

.btn-grad {background-image: linear-gradient(to right, #1FA2FF 0%, #12D8FA  51%, #1FA2FF  100%)}
.btn-grad {
   margin: 10px;
   padding: 15px 45px;
   text-align: center;
   text-transform: uppercase;
   transition: 0.5s;
   background-size: 200% auto;
   color: white;            
   /* box-shadow: 0 0 20px #eee; */
   border-radius: 4px;
   /* display: block; */
   font-size: 13px;
    border: 1px solid white;
   
 }

 .btn-grad:hover {
   background-position: right center; /* change the direction of the change here */
   color: #ffff;
   text-decoration: none;
 }
 


