.loader {
  border: 5px solid blue;
  /* #f3f3f3 */
  border-radius: 50%;
  border-top: 5px solid black;
  width: 100px;
  height: 100px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}
.alignCenter {
  position: fixed;
  top: 50%;
  left: 50%;
}
.alignModal {
  position: fixed;
  top: 46%;
  left: 46%;
}
.alignBlockedAccount {
  position: fixed;
  top: 40%;
  left: 47%;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
