.wrapper {
  width: 100%;
  max-width: 1024px;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  column-gap: 10px;
  border-bottom: 1px solid #333333;
  padding: 20px 5px;
  .userInfo {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    align-items: flex-start;
    justify-content: space-between;

    .info {
      display: flex;
      flex-direction: column;
      row-gap: 10px;
      .infoTop {
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
        column-gap: 7px;
        .name {
          color: #808080;
          font-weight: 700;
          font-size: 14px;
        }
        img[alt="verified"] {
          max-width: 100%;
          height: auto;
        }
        .followers {
          font-weight: 700;
          color: #0048ff;
          font-size: 14px;
        }
      }
      .nickname {
        color: #737373;
        font-weight: 500;
        font-size: 14px;
      }
    }

    .follow {
      button {
        width: 100px;
        height: 40px;
        border: 1px solid transparent;
        border-radius: 20px;
        color: white;
        text-transform: uppercase;
        font-weight: 700;
        outline: none;
        font-size: 12px;
        background: #0048ff;
      }
    }
  }
}
