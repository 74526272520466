.wrapper {
  width: 100%;
  max-width: 1024px;
  .row {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    column-gap: 5px;
    margin-bottom: 2px;
    .mediaItem {
      max-width: 320px;
      min-width: 320px;
      max-height: 320px;
      min-height: 320px;
      margin-bottom: 4px;
      overflow: hidden;

      .image {
        max-width: 100%;
        min-width: 100%;
        height: 320px;
        z-index: 500;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        display: inline-block;
        // max-height: 320px;
        // min-height: 320px;
      }
    }
  }
}
