.textStyle {
  margin-top: 6px !important;
  margin-left: 10px !important;
  font-size: 20px !important;
}
.parentStyle {
  display: block;
  margin-top: 5px !important;
}
.alignUser {
  display: flex;
}
