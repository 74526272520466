.wrapper_comment {
  display: flex;
  width: 100%;
}
.input-group {
  border: 1px solid gray;
  padding: 10px;
  border-radius: 20px;
  display: inline-block;
  /* width: calc(100% + 200%); */
  /* width: 50px; */
  min-width: 700px;
}
.input-text {
  border: none;
  background: transparent;
  color: white;
  font-size: 15px;
  width: 85%;
  outline: none;
}
.input_mention {
  color: white;
}
.blinking-cursor {
  font-weight: 100;
  font-size: 30px;
  color: #2e3d48;
  -webkit-animation: 1s blink step-end infinite;
  -moz-animation: 1s blink step-end infinite;
  -ms-animation: 1s blink step-end infinite;
  -o-animation: 1s blink step-end infinite;
  animation: 1s blink step-end infinite;
}
[contenteditable="true"]:empty:before {
  content: attr(placeholder);
  display: block;
  color: #aaa;
}

@keyframes blink {
  from,
  to {
    color: transparent;
  }
  50% {
    color: white;
  }
}

@-moz-keyframes blink {
  from,
  to {
    color: transparent;
  }
  50% {
    color: white;
  }
}

@-webkit-keyframes blink {
  from,
  to {
    color: transparent;
  }
  50% {
    color: black;
  }
}

@-ms-keyframes blink {
  from,
  to {
    color: transparent;
  }
  50% {
    color: black;
  }
}

@-o-keyframes blink {
  from,
  to {
    color: transparent;
  }
  50% {
    color: black;
  }
}
