.reportButton {
  width: 80%;
  margin-left: 10% !important;
  margin-right: 10% !important;
  margin-bottom: 20px !important;
  margin-top: 20px !important;
}

.textStyle {
  width: 400px;
  margin-left: 8px !important;
}
