.wrapper {
  width: 100%;
  max-width: 1024px;
  display: flex;
  align-items: center;
  flex-direction: column;
  .header {
    width: 100%;
    min-height: 500px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    padding: 20px;
    // background-color: #262626;
    position: relative;
    .topBar {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      .customize {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        column-gap: 10px;
        .customizeIcon {
          cursor: pointer;
          img {
            max-width: 100%;
            height: auto;
          }
        }
        .customizeText {
          font-size: 14px;
          cursor: pointer;
        }
      }
      .settings {
        cursor: pointer;
        img {
          max-width: 100%;
          height: auto;
        }
      }
      .buyText {
        cursor: pointer;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        text-align: left;
        color: #ffd949;
      }
    }
    .headerContent {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      position: relative;
      bottom: -120px;

      .levelBar {
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
        column-gap: 10px;
        margin-top: 20px;
        .coins {
          display: flex;
          flex-wrap: nowrap;
          align-items: center;
          column-gap: 10px;
          .coinIcon {
            cursor: pointer;
            img {
              max-width: 100%;
              height: auto;
            }
          }
          .coinText {
            font-size: 14px;
            font-weight: 500;
          }
        }
        .progressBar {
          min-width: 150px;
          max-width: 170px;
          height: 15px;
          border-radius: 3px;
          background: #666;
        }
        .xpPoints {
          font-weight: bold;
          font-size: 14px;
          line-height: 20px;
          text-align: center;
          color: #fff;
        }
      }
      .followBar {
        display: flex;
        align-items: center;
        column-gap: 20px;
        margin: 10px 0;

        .followText {
          font-size: 14px;
          line-height: 20px;
          text-align: center;
          color: #808080;
          span {
            font-weight: bold;
            font-size: 14px;
            line-height: 20px;
            text-align: center;
            color: #0048ff;
          }
        }
      }
      .friend {
        width: 100px !important;
        button {
          width: inherit;
          background: blue;
          border: 1px solid white;
          color: white;
          padding: 4px;
          border-radius: 6px;
        }
      }
      .userInfo {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: nowrap;
        margin-top: 10%;

        .userDetails {
          .nameWrapper {
            display: flex;
            align-items: center;
            flex-wrap: nowrap;
            column-gap: 10px;
            .username {
              font-weight: bold;
              font-size: 16px;
              line-height: 24px;
              text-align: left;
              color: #fff;
              margin-top: 20px !important;
            }
          }

          .nickname {
            font-weight: normal;
            font-size: 14px;
            line-height: 20px;
            text-align: left;
            color: #808080;
          }
          .itemDate {
            font-weight: normal;
            font-size: 14px;
            line-height: 20px;
            text-align: left;
            color: #808080;
          }
        }
        .level {
          padding: 10px;
          width: 70px;
          height: 63px;
          border-radius: 10px;
          background: transparent;
          border: 1px solid #0048ff;
          display: flex;
          flex-direction: column;
          row-gap: 2px;
          align-items: center;
          justify-content: center;
          margin-top: 25px;
          .levelHeading {
            text-transform: uppercase;
            font-weight: normal;
            font-size: 12px;
            line-height: 20px;
            text-align: center;
            color: #0048ff;
            border: 1px solid rgba(0, 0, 0, 0);
          }
          .currentLevel {
            font-weight: 500;
            font-size: 22px;
            text-align: center;
            color: #0048ff;
          }
        }
      }
    }
  }
}
.buttonStyle {
  background-color: transparent !important;
  border: none !important;
  color: #868686 !important;
  outline: none;
}
.customizeButton {
  border: none;
  background: none;
  color: #fff;
  font-size: 14px;
  cursor: pointer;
  outline: none;
}
.imgStyle {
  margin-right: 10px;
  height: 20px;
  width: 20px;
}
.parent {
  position: relative;
  top: 0;
  left: 0;
}
.image1 {
  position: relative;
  top: 0;
  left: 0;
}
.image2 {
  position: absolute;
  top: 22px;
  left: 24px;
}
.image3 {
  position: relative;
  top: 0;
  left: 0;
}
.friendContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}
.reportIcon {
  background: black;
  border-radius: 5px;
  height: 24px;
  width: 24px;
}
