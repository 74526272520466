.appHeader {
  background: black !important;
  margin-top: 30px;
  padding: 20px;
}
.dialogImage {
  height: inherit;
  width: 400px;

  padding: 10px;
}
.dialogFooter {
  display: grid;
}
.dialogFooter div {
  margin: auto;
}
.buttonStyle {
  background-color: yellow;
  color: black;
  border: none;
  height: 40px;
  width: 90px;
  border-radius: 10px 10px 10px 10px;
}
.centerCoin {
  margin-left: 40%;
  margin-top: 10px;
}
.imgStyles {
  height: 20px;
  width: 20px;
  padding-top: 5px;
}
