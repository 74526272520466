.dialog {
  margin-left: 50%;
  margin-top: 10%;
  margin-right: 10%;
  border: 0px;
  border-color: white;
}
.parent {
  margin: 1%;
  margin-left: 22px !important;
}
.icon {
  cursor: pointer;
  img {
    max-width: 100%;
    margin-top: 8px !important;
  }
}
.link {
  color: #808080 !important;
}
.sublink {
  margin: 0.5%;
  color: #808080;
  margin-bottom: 20px;
  margin-left: 4% !important;
}
.switch {
  margin-left: 7% !important;
}
.header {
  color: #ffff;
}
.wrapper {
  margin-top: 10px;
  margin-left: 0px;
}
.leaveFeedBack {
  color: #2196f3 !important;
}
.horizontalLine {
  color: #262626;
}
.savePost {
  color: #808080 !important;
  padding: 0 !important;
}
.notifications {
  margin-left: 16px !important;
  color: #808080 !important;
  justify-content: center;
  align-self: center;
}
.privateAccount {
  margin-left: 16px !important;
  color: #808080 !important;
}
.switchPrivate {
  margin-left: 2% !important;
}
.accountSetting {
  padding: 0;
  color: #808080 !important;
}
.infoHelp {
  padding: 0;
  color: #808080 !important;
}
.gameOver {
  padding: 0;
  color: #808080 !important;
}
.notifContainer {
  display: flex;
  flex-direction: row;
}
.notifInnerContainer {
  display: flex;
  flex: 0.7;
  flex-direction: row;
}
.notifIconContainer {
  flex: 0.1;
}
.notifImageInnerContainer {
  display: flex;
  flex: 1;
  height: 100%;
  margin: 0;
  padding: 0;
  align-items: center;
}
.notifImageStyles {
  height: 30px;
  width: 50px;
  margin-right: 5px;
}
.notifImageStyles path {
  fill: white;
}
.textContainer {
  flex: 0.9;
}
.toggleButtonContainer {
  flex: 0.3;
}
.toggleButtonInnerContainer {
  display: flex;
  flex: 1;
  padding: 0;
  margin: 0;
  justify-content: flex-end;
  vertical-align: center;
}
.margin {
  margin-top: 10px;
}
.marginInfo {
  margin-top: 15px;
}
.imageStyles {
  height: 30px;
  width: 50px;
  margin-right: 5px;
}
.gameOverContainer {
  display: flex;
  flex-direction: row;
  cursor: pointer;
}
.gameOverStyles {
  height: 30px;
  width: 50px;
  padding: 5px;
  margin-right: 5px;
  margin-top: 8px;
}
.donateStyles {
  height: 30px;
  width: 50px;
  margin-right: 6px;
  margin-top: 8px;
  padding: 5px;
}
.donateTextStyles {
  font-family: sans-serif;
  font-size: 16;
}
