.wrapper {
  width: 100%;
  max-width: 1024px;
  margin-bottom: 50px;
  .tabs {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    column-gap: 20px;
    .tabItem {
      padding: 20px;
      border-bottom: 1px solid transparent;
      width: 80px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .img path {
        stroke: blue;
        stroke-width: 1px;
        fill: black;
        max-width: 100%;
        height: auto;
        cursor: pointer;
      }
      .img line {
        stroke: blue;
      }
      .img circle {
        stroke: blue;
       
      }
      img {
        max-width: 100%;
        height: auto;
        cursor: pointer;
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .wrapper {
    display: flex;
    justify-content: space-between;
    .tabs {
    }
  }
}
