.addIcon {
  color: white;
}
.whiteCircle {
  height: 25px;
  width: 25px;
  border-radius: 50%;
  margin: auto;
  border: 1px solid white;
}
.blueCircle {
  height: 80px;
  width: 80px;
  border-radius: 50%;
  display: inline-block;
  margin-left: 30px;
  border: 1px solid blue;
  cursor: pointer;
  display: grid;
}
.parent {
  height: 60px;
  width: 120px;
  border: 1px solid #868686;
  cursor: pointer;
  display: grid;
  margin-left: 20px;
}
.parent div {
  margin: auto;
}
.displayInline {
  display: flex;
}
