.wrapper {
  width: 100%;
  max-width: 1024px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #333333;
  padding: 20px 5px;

  .item {
    width: 70%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    column-gap: 10px;
    .itemInfo {
      .name {
        font-weight: bold;
        font-size: 14px;
        text-align: left;
        color: #fff;
        margin-bottom: 5px;
      }
      .type {
        font-weight: normal;
        font-size: 14px;
        text-align: left;
        color: #808080;
        margin-bottom: 3px;
      }
      .date {
        font-weight: normal;
        font-size: 12px;
        text-align: left;
        color: #808080;
      }
    }
  }
  .review {
    width: 30%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    column-gap: 10px;
    .points {
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      text-align: left;
    }
    .point_success {
      color: #0dff27;
    }
    .point_danger {
      color: #db462c;
    }
    .icon {
      width: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        max-width: 100%;
        height: auto;
      }
    }
  }
}
.editDelete {
  cursor: pointer;
}
.feedback {
  margin-top: 4px;
}
